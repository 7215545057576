import React from "react"
import ReviewWidget from "../components/review_widget"
import Layout from "../components/layout"

export default () => {
  return (
    <Layout>
      <div>Hello world!!!!</div>
      <ReviewWidget merchantId="5af6040fb5b7ba004beaf304" />
    </Layout>
  )
}

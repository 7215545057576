import { Helmet } from "react-helmet"
import React, { CSSProperties } from "react"

export interface ReviewWidgetProps {
  merchantId: string
  style?: CSSProperties
}

export default ( { merchantId, style }: ReviewWidgetProps ) => {
  return (
    <>
      <div id="quick-feedback-reviews-widget" style={ { height: "100%", ...style } }></div>
      <Helmet>
        <script type='text/javascript'>{`
          (function() {
            console.log('test')
            var script = document.createElement( 'script' );
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://quick-feedback.co/reviews-widget/widget.js?merchant_id=${ merchantId }';
            var embedder = document.getElementById( 'quick-feedback-reviews-widget' );
            embedder.parentNode.insertBefore( script, embedder );
          })();
        `}</script>
      </Helmet>
    </>
  )
}
